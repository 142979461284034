.restaurant-header.header-nav.fixed-header,
.restaurant-header.header-nav.fixed-header.fixed-top,
.navbar.restaurant-header.header-nav.fixed-header,
.dark-purple.restaurant-header.header-nav.fixed-header {
    background: rgba(33, 106, 217, 0.95) !important;
    background-color: rgba(33, 106, 217, 0.95) !important;
}

.restaurant-header.header-nav,
.navbar.restaurant-header.header-nav {
    background: transparent !important;
    transition: all 0.4s ease;
    padding: 20px 0;
    border-bottom: none;
}

.restaurant-header.scrolled {
    background: rgba(33, 106, 217, 0.95) !important;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px 0;
}

.restaurant-header .navbar-brand {
    padding: 0;
    margin-right: 40px;
    transition: transform 0.3s ease;
}

.restaurant-header .navbar-brand:hover {
    transform: translateY(-3px);
}

.restaurant-header .nav-link {
    color: white !important;
    font-weight: 500;
    padding: 10px 16px !important;
    position: relative;
    transition: all 0.3s ease;
    opacity: 0.9;
}

.restaurant-header .nav-link span {
    position: relative;
    z-index: 1;
}

.restaurant-header .nav-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 3px;
    background-color: #FF6A3D;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: right;
}

.restaurant-header .nav-link:hover {
    color: white !important;
    opacity: 1;
}

.restaurant-header .nav-link:hover:before {
    transform: scaleX(1);
    transform-origin: left;
}

.restaurant-header .login-link {
    margin-right: 10px;
}

.restaurant-header .cta-button {
    margin-left: 10px;
}

.restaurant-header .btn-get-started {
    background-color: #FF8A5E;
    color: white !important;
    padding: 12px 24px;
    border-radius: 30px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 14px;
    transition: all 0.3s ease;
    display: inline-block;
    box-shadow: 0 4px 12px rgba(255, 138, 94, 0.3);
    text-decoration: none;
}

.restaurant-header .btn-get-started:hover {
    background-color: #FF9F7E;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(255, 138, 94, 0.4);
}

.restaurant-header .btn-get-started.pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 138, 94, 0.5);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 138, 94, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 138, 94, 0);
    }
}

.restaurant-header .try-yourself-btn {
    background: rgba(255, 138, 94, 0.15) !important;
    padding: 8px 16px !important;
    border-radius: 20px !important;
    margin: 0 5px;
    transition: all 0.3s ease;
}

.restaurant-header .try-yourself-btn span {
    color: #FF8A5E !important;
    font-weight: 600;
}

.restaurant-header .try-yourself-btn:hover {
    background: rgba(255, 138, 94, 0.25) !important;
    transform: translateY(-2px);
}

.restaurant-header .try-yourself-btn:before {
    display: none;
} 