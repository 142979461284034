.meeting-form-container {
    min-height: 100vh;
    background: #ffffff;
    padding: 0;
}

.logo-section {
    text-align: center;
    padding: 1.25rem;
    background: #2563eb;
    margin-bottom: 3rem;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-link {
    display: inline-block;
    transition: transform 0.2s ease;
}

.logo-link:hover {
    transform: scale(1.05);
}

.company-logo {
    height: 45px;
    width: auto;
    object-fit: contain;
    vertical-align: middle;
    transform: scale(1.5);
    transform-origin: center;
}

.form-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem 2rem;
}

.content-grid {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    gap: 3rem;
    align-items: start;
}

.welcome-section {
    padding: 3rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 2rem;
}

.welcome-section h1 {
    color: #1e293b;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
}

.welcome-text {
    color: #64748b;
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.benefits {
    margin-top: 0;
}

.benefit-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    background: #f8fafc;
    border-radius: 8px;
    transition: transform 0.2s;
    border: 1px solid #e5e7eb;
}

.benefit-item:hover {
    transform: translateX(5px);
    background: #f1f5f9;
    border-color: #2563eb;
}

.benefit-item i {
    color: #2563eb;
    font-size: 1.5rem;
    margin-right: 1rem;
    flex-shrink: 0;
    margin-top: 0.2rem;
}

.benefit-item span {
    color: #1e293b;
    font-size: 1.1rem;
    line-height: 1.5;
}

.benefit-item:last-child {
    margin-bottom: 0;
}

.form-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e5e7eb;
}

.form-header h2 {
    color: #1e293b;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.form-header .subtitle {
    color: #64748b;
    font-size: 1.1rem;
}

.form-field {
    margin-bottom: 1.5rem;
}

.form-field label {
    display: block;
    margin-bottom: 0.5rem;
    color: #1e293b;
    font-weight: 500;
}

.custom-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    transition: all 0.2s;
}

.custom-input:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.custom-dropdown {
    width: 100%;
}

.custom-dropdown .p-dropdown {
    width: 100%;
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    transition: all 0.2s;
}

.custom-dropdown .p-dropdown:hover {
    border-color: #2563eb;
}

.custom-dropdown .p-dropdown:focus {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
    border-color: #2563eb;
}

.custom-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.95rem;
    line-height: 1.5;
    transition: all 0.2s;
    resize: vertical;
    min-height: 100px;
}

.custom-textarea:hover {
    border-color: #2563eb;
}

.custom-textarea:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
    border-color: #2563eb;
}

.upload-section {
    border: 2px dashed #e5e7eb;
    padding: 2rem;
    border-radius: 8px;
    background: #f8fafc;
    text-align: center;
    transition: all 0.3s;
}

.upload-section:hover {
    border-color: #2563eb;
    background: #f1f5f9;
}

.drag-drop-zone {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 0;
    text-align: center;
    background: #fafafa;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.drop-zone-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px;
    pointer-events: none;
}

.drag-drop-zone:hover {
    border-color: #2563eb;
    background: #f8fafc;
}

.drag-drop-zone.dragging {
    border-color: #2563eb;
    background: #eff6ff;
    border-style: solid;
    transform: scale(1.01);
}

.drag-drop-zone .icon {
    font-size: 32px;
    color: #2563eb;
    margin-bottom: 16px;
    display: block;
}

.drag-drop-zone p {
    margin: 0;
    color: #4b5563;
    font-size: 14px;
    line-height: 1.5;
}

.file-list {
    margin-top: 16px;
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #e5e7eb;
}

.file-item {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #f9fafb;
    border-radius: 6px;
    margin-bottom: 8px;
    transition: all 0.2s ease;
}

.file-item:hover {
    background: #f3f4f6;
}

.file-item:last-child {
    margin-bottom: 0;
}

.file-item .file-name {
    flex: 1;
    margin-right: 12px;
    font-size: 14px;
    color: #374151;
}

.file-item .file-size {
    color: #6b7280;
    font-size: 12px;
    margin-right: 16px;
    white-space: nowrap;
}

.file-item img {
    border-radius: 4px;
    margin: 0 12px;
    border: 1px solid #e5e7eb;
}

.file-item .p-button-rounded {
    width: 2rem;
    height: 2rem;
}

.submit-button {
    background: #2563eb !important;
    border: none !important;
    padding: 1rem 2rem !important;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    transition: all 0.2s !important;
}

.submit-button:hover {
    background: #1d4ed8 !important;
    transform: translateY(-1px);
}

.error-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 6px;
    background: #fee2e2;
    color: #dc2626;
}

.success-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 6px;
    background: #dcfce7;
    color: #16a34a;
}

.try-it-section {
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
}

.try-it-section h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
    color: #1e293b;
}

.try-it-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #64748b;
}

.demo-phone {
    margin: 1.5rem 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.phone-link {
    color: #2563eb;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s;
}

.phone-link:hover {
    color: #1d4ed8;
}

.try-it-note {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #64748b;
    margin-top: 1.5rem;
}

.custom-select-button {
    width: 100%;
    margin-top: 0.5rem;
}

.custom-select-button .p-selectbutton {
    display: grid;
    width: 100%;
    gap: 0.75rem;
}

.custom-select-button.three-options .p-selectbutton {
    grid-template-columns: repeat(3, 1fr);
}

.custom-select-button.four-options .p-selectbutton {
    grid-template-columns: repeat(4, 1fr);
}

.custom-select-button.two-options .p-selectbutton {
    grid-template-columns: repeat(2, 1fr);
}

.custom-select-button .p-selectbutton .p-button {
    width: 100%;
    justify-content: center;
    background: white;
    border: 1px solid #e2e8f0;
    color: #64748b;
    font-weight: 500;
    padding: 0.75rem 1rem;
    border-radius: 8px !important;
    transition: all 0.2s;
}

.custom-select-button .p-selectbutton .p-button:hover {
    border-color: #2563eb;
    color: #2563eb;
    background: #f8fafc;
}

.custom-select-button .p-selectbutton .p-button.p-highlight {
    background: #2563eb;
    border-color: #2563eb;
    color: white;
}

.custom-select-button .p-selectbutton .p-button.p-highlight:hover {
    background: #1d4ed8;
    border-color: #1d4ed8;
}

.other-carrier-input {
    margin-top: 0.75rem;
}

.other-carrier-input input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s;
}

.other-carrier-input input:hover {
    border-color: #2563eb;
}

.other-carrier-input input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.availability-section {
    background: #f8fafc;
    padding: 1.25rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
}

.days-select .p-selectbutton {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.4rem;
    width: 100%;
}

.time-select .p-selectbutton {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.4rem;
    width: 100%;
}

.days-select .p-selectbutton .p-button,
.time-select .p-selectbutton .p-button {
    background: white;
    border: 1px solid #e2e8f0;
    color: #64748b;
    font-weight: 500;
    padding: 0.5rem;
    font-size: 0.875rem;
    border-radius: 6px !important;
    transition: all 0.2s;
    justify-content: center;
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.time-select .p-selectbutton .p-button {
    padding: 0.5rem 0.75rem;
    text-align: center;
    line-height: 1.2;
}

.days-select .p-selectbutton .p-button:hover,
.time-select .p-selectbutton .p-button:hover {
    border-color: #2563eb;
    color: #2563eb;
    background: #f8fafc;
    box-shadow: 0 2px 4px rgba(37, 99, 235, 0.1);
}

.days-select .p-selectbutton .p-button.p-highlight,
.time-select .p-selectbutton .p-button.p-highlight {
    background: #2563eb;
    border-color: #2563eb;
    color: white;
    box-shadow: 0 2px 4px rgba(37, 99, 235, 0.2);
}

.days-select .p-selectbutton .p-button.p-highlight:hover,
.time-select .p-selectbutton .p-button.p-highlight:hover {
    background: #1d4ed8;
    border-color: #1d4ed8;
    box-shadow: 0 2px 4px rgba(29, 78, 216, 0.2);
}

.time-selection {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid #e2e8f0;
}

@media (max-width: 768px) {
    .days-select .p-selectbutton {
        grid-template-columns: repeat(3, 1fr);
    }

    .time-select .p-selectbutton {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 1024px) {
    .content-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .welcome-section {
        position: static;
        order: 2;
    }

    .form-card {
        order: 1;
    }

    .try-it-section {
        padding: 1.75rem;
    }

    .try-it-section h3 {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }

    .try-it-text {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    .days-select .p-selectbutton {
        grid-template-columns: repeat(3, 1fr);
    }

    .time-select .p-selectbutton {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 640px) {
    .meeting-form-container {
        padding: 0;
    }

    .welcome-section {
        padding: 1.5rem;
    }

    .form-card {
        padding: 1.5rem;
    }

    .welcome-section h1 {
        font-size: 1.75rem;
    }
}
